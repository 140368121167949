


/** Start How to sponsor Page **/

.how-sponsor,
.our-sponsor {
    /* margin-top: 10rem; */
    margin-top: 2.5rem;
    padding: 0 15px;
}

.how-sponsor h2,
.our-sponsor h2 {
    margin: 30px 0 30px;
    color: #ed2124;
    font-weight: bold
    
}

.how-sponsor h2 {
    margin-top: 0;
}

.how-sponsor h2::after,
.our-sponsor h2::after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 110px;
    display: block;
    border: 1.5px solid #384078
}

.our-sponsor img {
    width: 100%
}

.how-sponsor p,
.our-sponsor p {
    color: #6c6c6c;
    font-size: 19px;
    line-height: 2.1;
    margin-bottom: 30px
}


.brands {
    padding: 30px 0 0px;
    background-color: #f7f7f7;
    margin-top: 30px
}

.brands img {
    opacity: .3;
    transition: .5s all ease;
    margin-bottom: 30px;
    width: 60%;
    cursor: pointer;
}

.brands img:hover {
    opacity: .8;
    transition: .5s all ease;
}


.contact-us {
    overflow: hidden
}

.contact-us .contact-info {
    background-color: #ededed;
    background-image: url('../images/map.png');
    min-height: 450px;
}

.contact-us .contact-info h3 {
    margin: 30px auto 70px;
    color: #384078;
    font-weight: 600
}

.contact-us .contact-info .info {
    padding-left: 0;
    color: #212121;
    font-size: 16px;
    font-weight: 600;
}

.contact-us .contact-info .info li {
    display: flex;
    width: 220px;
    margin: 50px auto;
    align-items: center;
    justify-content: center;
}
.contact-us .contact-info .info i {
    font-size: 25px;
    width: 15%;
}

.contact-us .contact-info .info div {
    width: 85%;
    text-align: left;
    padding-left: 25px;
}

.contact-us .contact {
    background-color: #384078;
    padding: 30px 0 45px;
    min-height: 450px;
}

.contact-us .contact input {
    padding: 10px 15px 10px 60px;
    color: #FFF;
    margin: 30px 10px 0;
    font-size: 17px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 18px;
    background-position: 15px 12px;
}

.contact-us .contact input::placeholder {
    color: #CCC;
    font-weight: 600;
    font-size: 16px
}
/* .contact-us .contact input:focus {
    outline: none;
    border: 2px solid #ed2124
} */
.contact-us .contact .user {
    background-image: url('../images/icons/profile.png');
    width: 39%;
}

.contact-us .contact .email {
    background-image: url('../images/icons/envelope.png');
    width: 80%;
}

.contact-us .contact .subject {
    width: 80%;
    padding: 10px 15px;
}

.contact-us .contact .message {
    width: 80%;
    padding: 10px 15px;
}

.contact-us .contact button {
    background: #FFF;
    padding: 10px 40px;
    border-radius: 50px;
    display: block;
    margin: 30px auto 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #212121;
    transition: .5s all ease
}


.contact-us .contact button:hover {
    background: #212121;
    color: #FFF;
    transition: .5s all ease;
}

/** End How to Sponsor Page **/

/** Start How it Works **/
.quote p {
    padding: 0 60px 60px;
    font-size: 17px;
    color: #6c6c6c;
    line-height: 1.8
}

.how-work {
    background-color: #efefef;
    padding: 20px 0
}
.how-work h2 {
    margin: 30px 0 60px;
    color: #ed2124;
    font-weight: bold
    
}

.how-work h2::after {
    content: "";
    position: relative;
    top: 0;
    left: -33px;
    width: 110px;
    margin: auto;
    display: block;
    border: 1.5px solid #384078
}


.how-work .icon {
    border-radius: 50%;
    background-color: #FFF;
    width: 80px;
    height: 80px;
    margin: auto;
    padding-top: 15px;
}

.how-work h3 {
    font-family: 'Signika', sans-serif;
    color: #384078;
    margin-top: 40px ;
    margin-bottom: 20px

}

.how-work p {
    padding: 20px;
    margin-bottom: 30px;
    color: #6f6f6f;
    line-height: 1.9;
    font-weight: 600
}

/* Games and rules **/
.g-r {
    padding: 20px
}
.g-r h2 {
    margin: 30px 0 40px;
    color: #ed2124;
    font-weight: bold
    
}

.g-r h2::after {
    content: "";
    position: relative;
    top: 0;
    left: 0px;
    width: 50px;
    display: block;
    border: 1.5px solid #384078
}

.g-r p {
    
    padding:0;
    color: #6f6f6f;
    line-height: 1.9;
    font-weight: 600
}

.games .games-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.games .game-image {
    width: 48%;
    background-color: #6f6f6f;
    display: inline-block;
    margin: 2% 0;
    box-shadow: 0 0 8px #ccc;
}

.games .game-image img {
    width: 100%;
}

.rules h2 {
    margin-top: 10rem;
    margin-bottom: 5rem;
}


.rules .rule-text span {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ed2124;
    display: inline-block;
    float: left;
    clear: both;
    margin-bottom: 20px;
    
}

.rules div:nth-of-type(2) span,
.rules div:nth-of-type(4) span,
.rules div:nth-of-type(6) span {
    
    background-color: #384078;

}

.rules .rule-text p {
    padding: 0;
    margin: 0;
    display: inline-block;
    float: right;
    clear: both;
    color: #222;
    margin: -11% 0 50px 10%;
}
/** How to Claim **/
.how-claim {
    padding: 30px 30px 60px 30px;
    background-color: #efefef
}
.how-claim h2 {
    margin: 30px 0 40px;
    color: #ed2124;
    font-weight: bold
    
}

.how-claim h2::after {
    content: "";
    position: relative;
    top: 0;
    left: -32px;
    margin: auto;
    width: 115px;
    display: block;
    border: 1.5px solid #384078
}

.claim .claim-text {
    display: inline-block;
    margin-top: 30px
}

.claim .claim-text span {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ed2124;
    display: inline-block;
    margin-bottom: 20px;
    float: left;
    
}

.claim div:nth-of-type(2) span,
.claim div:nth-of-type(4) span {
    
    background-color: #384078;

}

.claim .claim-text p {
    padding: 0;
    margin: 0;
    display: inline-block;
    color: #222;
    float: left;
    margin-left: 20px;
    margin-top: 5px;
}


/** End How it Works Page **/

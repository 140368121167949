/* body,html {
    overflow-x: hidden
} */


/** Start Wallet Page **/

/** navbar **/
.navbar-default .navbar-nav > li > a {
    padding: 15px 20px 0px;
}

.navbar-nav .account a {
    padding-top: 10px !important;
    color: #222 !important
}

.navbar-nav .account i {
    font-size: 24px ;
    margin-right: 10px;
    color: #6c6c6c
}

.dropdown-menu > li > a {
    padding: 10px 20px 10px 25px;
    /* color: #ed2124; */
    font-weight: bold;
    font-size: 16px;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    background-color: #fff;
    color: #ed2124;
}

.dropdown-menu > .active.no-hover > a, .dropdown-menu > .active.no-hover > a:hover, .dropdown-menu > .active.no-hover > a:focus {
    background-color: #fff;
    color: inherit;
}


.dropdown-menu > li > a:hover {
    background-color: #384078;
    color: #FFF
}

.dropdown-menu > li:not(.active) > a:hover .badge {
    background-color: #FFF;
    color: #384078;
}

/** wallet body **/

.wallet {
    padding: 40px;
}

.wallet h2 {
    color: #ed2124;
    margin: 60px 0 50px;
    font-weight: bold;
    font-size: 25px
    
}

.wallet h2::after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 45px;
    display: block;
    border: 1.5px solid #384078
}

.wallet .profile-info {
    padding-left: 2%
}

.wallet .profile-info img {
    border-radius: 50%;
    display: inline-block;
    float: left;
    margin-right: 20px
}

.wallet .profile-info h3 {
    padding-top: 40px;
    font-weight: bold;
    color: #222
}

.wallet .profile-info .balance {
    float: right;
    padding-right: 40px
}

.wallet .profile-info .balance p {
    display: inline-block;
    font-size: 20px;
    color: #222
}

.wallet .profile-info .balance span {
    color: #ed2124;
    font-weight: bold;
    padding-right: 30px;
    padding-left: 10px
}


.wallet .profile-info .balance button {
    font-size: 20px;
    color: #FFF;
    background: #222;
    border-radius: 50px;
    padding: 5px 30px;
    transition: .5s all ease;

}

.wallet .profile-info .balance button a {
    text-decoration: none;
    color: #FFF
}

.wallet .profile-info .balance button:hover {
    border: 1px solid #222;
    background: #FFF;
    font-weight: bold;
    transition: .5s all ease;
}

.wallet .profile-info .balance button:hover a {
    color: #384078

}

.wallet .wallet-info {
    margin-top: 100px;
    border: 2px solid #a8a8a8;
    padding: 20px 60px;
    margin-bottom: 50px
}


.wallet-info .tabs h3 {
    font-size: 25px;
    display: inline-block;
    color: #6c6c6c;
    cursor: pointer;
    margin-right: 40px
    
}

.wallet-info .tabs .active {
    color: #384078;
    padding-bottom: 5px;
    border-bottom: 3.5px solid #ed2124

}


.wallet-info .tabs hr {
    margin-top: -11px; 
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #a8a8a8;
}

/** Summary Info **/

.wallet-info .summary-info {
    font-size: 18px;
    padding: 40px
}

.wallet-info .summary-info p {
    margin-top: 20px;
    font-weight: bold
}

.wallet-info .summary-info span {
    color: #384078;
    margin-left: 5px
}

.wallet-info .summary-info .prizes {
    display: inline-block;
    margin-right: 20px
}

.wallet-info .summary-info .prizes h3 {
    color: #384078;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px
}

.wallet-info .summary-info .prizes h3,
.wallet-info .summary-info .prizes h4 {
    text-align: center
}

.wallet-info .summary-info .prize {
    width: 100px; 
    height: 100px;
    border-radius: 50%;
    border: 1px solid #384078;
    display: inline-block;
    margin-top: 20px;
    margin-right: 10px;
    overflow: hidden
}

.wallet-info .summary-info .prize img {
    margin: auto;
}

.wallet-info .summary-info .claimed {
    color: #2cb742;
    font-weight: 600
}


.wallet-info .summary-info .unclaimed {
    color: #ed2124;
}

/** History Info **/
.wallet-info .history-info {
    font-size: 16px;
    padding: 40px
}


.wallet-info .history-info h3 {
    margin-top: 20px;
    font-weight: bold;
    color: #222;
    font-size: 20px
}

.wallet-info .history-info p {
    margin-left: 3rem;
    color: #384078;
    font-weight: bold;
}

.wallet-info .history-info span {
    color: #ed2124;
}

.wallet-info .history-info .pending {
    color: #6c6c6c;
    font-weight: normal
}


/** End Wallet Page **/


/** Start Withdraw Page **/
.withdraw {
    padding: 40px 0 60px
}

.withdraw h2 {
    color: #ed2124;
    margin: 60px 0 50px;
    font-weight: bold;
    font-size: 25px
    
}

.withdraw h2::after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 45px;
    display: block;
    border: 1.5px solid #384078
}


.withdraw .withdraw-info,
.withdraw .withdraw-q {
    border: 1px solid #DDD;
    margin: 10px;
    padding: 20px 0 60px ;
}

.withdraw .withdraw-info h3,
.withdraw .withdraw-q h3 {
    color: #384078;
    padding: 20px
}

.withdraw .withdraw-info ul {
    margin-left: -13px;
    padding-left: 0
}
.withdraw .withdraw-info li {
    margin-top: 30px
}

.withdraw .withdraw-info p {
    font-size: 17px;
    margin-left: 4rem;
    color: #6c6c6c
}


.withdraw .withdraw-info li span {
    color: #ed2124;
    font-weight: bold
}
.withdraw .withdraw-info li:nth-of-type(1) span,
.withdraw .withdraw-info li:nth-of-type(2) span {
    color: #222
}

.withdraw .withdraw-info input {
    width: 80px;
    margin-right: 10px;
    color: #384078;
    font-weight: bold
}

.withdraw .withdraw-info button {
    padding: 5px 30px;
    font-size: 20px;
    border-radius: 10px;
    background: #384078;
    margin-top: 35px;
    opacity: .9;
    transition: .5s all ease;

}

.withdraw .withdraw-info button a {
    color: #FFF;
    text-decoration: none
}

.withdraw .withdraw-info button:hover {
    opacity: 1;
    transition: .5s all ease;

}

.withdraw .withdraw-q  {
    padding-bottom: 3rem
}

.withdraw .withdraw-q .panel-default {
    border: none
}

.withdraw .withdraw-q .panel-default > .panel-heading {
    color: #333;
    text-decoration: underline;
    background-color: transparent; 
    border: none;
    margin: 50px;
}

.withdraw .withdraw-q .panel-group .panel {
    border: none;
    box-shadow: none

}

.withdraw .withdraw-q .panel-group .panel a {
    font-size: 20px;

}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ed2124;
    color: #384078;
    font-size: 17px
}


/** End Withdraw Page **/

/** Start Account Page **/
.account-page .head-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.account-page .edit-icon {
    font-size: 16px;
    cursor: pointer;
    color: #384078;
}

.account-page .head-area .refer-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.account-page .head-area h2 {
    margin-bottom: 0;
    padding-right: 30px;
}

.account-page .social {
    padding: 0 5px;    
}
.account-page .social i {
    color: #222;
    background: #bfbfbf;
    width: 30px;
    height: 30px;
    font-weight: bolder;
    text-align: center;
    margin: 5px;
    padding-top: 9px;
    transition: .5s all ease

}

.account-page .social i:hover {
    background: #ddd;
    cursor: pointer;
    transition: .5s all ease
}

.account-page .ui.styled.accordion, .ui.styled.accordion .accordion {
    margin: 0;
}

.accordion-wrap {
    margin: 0 -15px;
}

.title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion-content-wrap {
    padding: 0 30px;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.accordion-content-wrap form {
    max-width: 510px;
    width: 100%;
}

.summary-cards {
    display: flex;
    flex-wrap: wrap;
    
}

.summary-card-wrap {
    flex: 1;
    padding: 15px;
}

.summary-card {
    box-shadow: 0 0 10px #ccc;
    padding: 20px 25px;
}

.summary-card .card-label {
    color: #888;
    padding-bottom: 5px;
}

.summary-card .card-val-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.summary-card .card-val {
    text-transform: uppercase;
    font-size: 32px;
    color: #222;
    margin-right: 15px;
}

.summary-card .card-val-wrap button {
    display: block;
    background-color: #2cb742;
    color: #FFF;
    border-radius: 60px;
    padding: 8px 15px;
    transition: all .3s ease-in-out;
    border: 0;
}

.summary-card .card-val-wrap button:focus {
    outline: none;
}

.summary-card .card-val-wrap button:hover {
    opacity: 0.8;
}

.summary-card .card-val-wrap button.disabled {
    background-color: #888;
    color: #fff;
    opacity: 0.6;
}

.table {
    font-size: 14px;
}

.table .table-row, .table .header-row {
    /* display: flex; */
    /* padding: 10px 0; */
    border-bottom: 1px solid #ddd;
}

.table .header-row {
    border-left: 0;
    border-right: 0;
    font-weight: 600;
    color: #384078;
    background: #f9f9f9;
}

.table .cell {
    /* flex: 1; */
    /* padding: 0 15px; */
}
/** End Account Page **/

/** Start Games Page **/
.games {
    padding: 0px 0 0;
}

.games h2 {
    color: #ed2124;
    margin: 0px 0 30px;
    font-weight: bold;
    font-size: 25px
    
}

.games h2::after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 45px;
    display: block;
    border: 1.5px solid #384078
}

.games .game-tabs {
    display: flex;
    background: #fff;
    margin: 0 auto 45px;
    box-shadow: 0px 0 4px #ddd;        
}

.games .game-tabs .tab {
    flex: 1;
    /* background: #f2f2f2; */
    /* border: 1px solid #ddd; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #384078;
    cursor: pointer;
    border: 1px solid #f5f5f5;
    border-left: 0;
    text-align: center;
    width: 90px;
    height: 80px;
}

.games .game-tabs .tab:first-child {
    border: 1px solid #f5f5f5;
}

.games .game-tabs .tab.active {
    background: #384078;
    color: #fff;
    border: 1px solid #384078;
    border-left: 0;
}

.games .game-tabs .tab .status {
    height: 30px;
}

.games .game-tabs .tab .status i {
    color: #21ba45;
    font-size: 22px;
}

.games .game-tabs .tab .status .badge {
    background-color: #384078;
    color: #fff;
    font-size: 14px;    
}

.games .game-tabs .tab.active .status .badge {
    background-color: #fff;
    color: #384078;
}

.games .game-tabs .tab.active:first-child {
    border: 1px solid #384078;
}

.games .g-image {
    border: 1px solid #384078;
    margin: 20px
}

.games .g-image img {
    width: 100%
}

.ads-small .ad-here {
    margin-bottom: 4rem;
}
/** End Games Page **/

/** Start Daily Quiz **/

.content {
    padding-top: 5rem;
    /* margin-bottom: 5rem; */
    /* min-height: 600px; */
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
}

/* .content .previous-winners , 
.content .questions , 
.content .ads {
    min-height: 600px
} */

.content .previous-winners {
    padding: 15px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    display: inline-block;
    width: 100%;
    box-shadow: 0 2px 4px #bbb;
}

.content .previous-winners h3 {
    color: #384078;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 50px
}

.content .previous-winners .winner {
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
}

.content .previous-winners .winner img {
    border-radius: 50%;
    margin-right: 10px
}

.content .previous-winners .winner span {
    color: #222;
    font-size: 18px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}




/* .content .questions {
    padding: 0 60px 45px 5rem;
    
} */

.content .que-ans {
    width: 100%;
    margin-bottom: 3rem
}

.content .questions h3 {
    display: inline-block;
    padding-right: 20px;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #ED2124;
    padding: 0 15px 15px;
}

.content .questions .daily-draw {
    text-align: center;
    padding-top: 30px;
}


.content .questions .daily-draw span {
    background-color: #222;
    color: #FFF;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 1px
}

.daily-d-content h2 span {
    font-size: 20px;
    padding-left: 5px;
}


.play {
    padding: 0 15px
}

.play p,
.daily-results p {
    font-size: 22px;
    padding: 35px 45px;
    border: 0px solid #384078;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px #bbb;
}

.daily-results p span {
    color: #ED2124;
}

.play .btn {
    margin-right: 20px;
    padding: 8px 30px !important;
}

.score-result-box {
    margin-bottom: 25px;
}

.quiz-actual-score {
    text-align: center;
    font-size: 24px;
}

.score-status {
    text-align: center;
    padding: 25px 0;
}

.score-status i {
    font-size: 100px;
}

.score-status i.fa-check-circle {
    color: #21ba45;
}

.score-status i.fa-times-circle {
    color: #ed2124;
}

.score-status-text {
    text-align: center;
    font-size: 24px;
    color: #384078;
}

.score-status-subtext {
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
    color: #999;
}

.finished-result {
    padding: 0 !important;
    text-align: center !important;
}

.questions  {
    /* display: none; */
}
.questions .question-head {
    padding: 0px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.questions .question-head h4 {
    color: #6d6d6d;
    display: inline-block;
    font-size: 14px;
    margin-bottom: 0;
}

.questions .countdown {
    font-size: 28px;
    color: #404040;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    font-family: 'Ubuntu', Helvetica, sans-serif;
}

.questions .countdown .timer {
    font-size: 10px;
    text-indent: -9999em;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #ED2124;
    background: -moz-linear-gradient(top, #ED2124 50%, rgba(255, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, #ED2124 50%, rgba(255, 0, 0 0) 100%);
    background: -o-linear-gradient(top, #ED2124 50%, rgba(255, 0, 0 0) 100%);
    background: -ms-linear-gradient(top, #ED2124 50%, rgba(255, 0, 0 0) 100%);
    background: linear-gradient(to top, #ED2124 50%, rgba(255, 0, 0, 0) 100%);
    position: relative;
    -webkit-animation: load3 2s infinite linear;
    animation: load3 2s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.questions .countdown .timer:before {
    width: 50%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(255,0,0,1) 100%, rgba(255,0,0,0) 100%);
    border-radius: 100% 0% 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.questions .countdown .timer:after {
    background: #fff;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

.questions .countdown span {
    position: absolute;
    width: 90px;
    height: 90px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #384078;
}

.questions .que-ans .question {
    font-weight: 400;
    color: #222;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
}

.questions .answers {
    padding: 0 15px;
}

.questions .answers .options-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.questions .answers .options-wrap .option {
    width: calc(50% - 20px);
    background: #384078;
    border: 4px solid transparent;
    margin: 10px;
    color: #fff;
    padding: 5px 0px;
    border-radius: 20px;
    font-size: 1.6rem;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 4px #bbb;
}

.questions .answers .options-wrap .option.selected {
    border-color: #ED2124;
}

.questions .answers input {
    border: 1px solid transparent;
    width: 100%;
}

.questions .answers input:focus {
    border: 1px solid #384078;
    box-shadow: 0 0 5px #384078;
}

.questions .answers .btn ,
.play .btn {
    display: block;
    /* position: absolute;
    right: 0; */
    margin: 15px auto;
    background-color: #2cb742;
    color: #FFF;
    border-radius: 60px;
    padding: 7px 20px;
    transition: all .3s ease-in-out;
    font-size: 17px;
    /* margin-right: 15px */
}

.questions .answers .btn:hover,
.play .btn:hover {
    background-color: #222;
    transition: all .3s ease-in-out;
}


.ad-here {
    background-image: url('../images/auth-pages/ad.png');
    margin-right: 20px;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;
}

.next-round {
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
    color: #ed2124;
}

.w-ads .ad-here {
    background-image: url(../images/auth-pages/ad.png);
    background-size: cover;
    margin-right: 20px;
    min-height: 410px;
    margin-top: 60px;
}
/** End Daily Quiz **/

/** Start Main Draw **/

.weekly-draw {
    font-family: 'Roboto', sans-serif;
    padding-top: 6rem
}


.weekly-draw h3 {
    font-size: 20px
}

.weekly-draw h3 span {
    font-weight: bold
}

.weekly-draw h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 3rem;
    color: #233c6f
}

.weekly-draw h2 span {
    display: inline-block;
    background-color: #222;
    color: #FFF;
    padding: 10px 20px;
    border-radius: 5px;
    margin-left: 20px;
    letter-spacing: 1px;
    font-size: 16px
}


.weekly-draw .claim {
    background-color: #222;
    border-radius: 10px;
    padding: 7px 15px;
    width: 35rem;
    margin: auto;
    margin-top: 4%
}

.weekly-draw .claim img {
    border-radius: 50% ;
    margin-right: 10px
}

.weekly-draw .claim h3 {
    display: inline-block;
    color: #FFF;
    font-weight: bold;
    font-size: 21px;
    position: relative;
    top: 6px;
    margin-right: 20px;
    left: 0
}

.weekly-draw .claim button {
    background: rgba(44, 183, 66, .8) ;
    color: #FFF;
    font-size: 16px;
    border-radius: 30px;
    padding: 2px 20px;
    transition: .5s all ease
}

.weekly-draw .claim button:hover {
    background: rgba(44, 183, 66, 1) ;
    transition: .5s all ease

}

.next-weeklydraw h2 {
    font-size: 20px;
    font-weight: normal;
    margin-top: 7rem;
    color: #222
}



.top-winners h2 {
    color: #ed2124;
    margin: 60px 0 50px;
    font-weight: bold;
    font-size: 25px
    
}

.top-winners h2::after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 80px;
    display: block;
    border: 2px solid #384078
}

.top-winners .winner {
    margin-bottom: 3rem;
    font-size: 19px;
}

.top-winners .winner img {
    border-radius: 50%
}

.top-winners .winner span {
    font-size: 19px;
    margin: 0 10px;
}

.top-winners .winner .points {
    font-weight: bold;
    margin: 0;
    color: #384078;
}

.weekly-draw .map {
    margin-top: 60px
}

.weekly-draw .map img {
    width: 100%;
    height: 412px;
}


/** End Main Draw **/


/** Start Profile page **/

.edit-d .container {
    border: 1px solid #6c6c6c;
    margin-top: 90px
}
.edit-d h2 {
    color: #ed2124;
    margin: 60px 5px 50px;
    font-weight: bold;
    font-size: 25px;
    display: inline-block
}

.edit-d .head-info span {
    display: block;
    color: #919191;
    font-size: 14px;
    padding: 20px 10px 0
}

.edit-d .side-info {
    float: right;
    margin-top: 40px;
    padding-right: 25px;
}

.edit-d .side-info h3 {
    font-size: 17px;
    display: inline-block;
    margin-right: 10px
}

.edit-d .side-info h3 a {
    font-weight: bold;
    color: #384078
}

.edit-d .side-info .btn {
    background-color: #222;
    padding: 5px 30px;
    border-radius: 30px;
    font-size: 17px;
    transition: .5s all ease-in-out;
}

.edit-d .side-info .btn a {
    color: #FFF;
    text-decoration: none;

}

.edit-d .side-info .btn:hover {
    background-color: #384078;
    transition: .5s all ease-in-out;

}

.edit-d .user-id h3 {
    color: #ed2124;
    font-size: 20px;
    font-weight: bold
}

.edit-d .user-id h3 span:first-of-type {
    color: #384078;
    margin-left: 10px;
    margin-right: 20px
}

.edit-d .user-id h3 span:last-of-type {
    color: #919191;
    font-size: 15px;
}

/* form {
    margin: 60px
} */

label {
    color: #222;
    font-size: 17px;
    margin-right: 10px
}

label span {
    color: #ed2124;
    margin-right: 5px
}

input {
    border: 0;
    box-shadow: 0 0 5px #ddd;
    padding: 5px 15px;
    border-radius: 4px;
}

input:focus {
    outline: none;
    box-shadow: 0 0 8px #fff;
}

.edit-d .details input:nth-of-type(3),
.edit-d .details input:nth-of-type(4) {
    width: 30rem
}

.edit-payment .container {
    border: 1px solid #6c6c6c;
    margin-top: 30px
}

.edit-payment h2 {
    color: #ed2124;
    margin: 60px 5px 50px;
    font-weight: bold;
    font-size: 25px;
}


.change-password .container {
    border: 1px solid #6c6c6c;
    margin-top: 30px
}

.change-password h2 {
    color: #ed2124;
    margin: 60px 5px 50px;
    font-weight: bold;
    font-size: 25px;
}


.verify .btn {
    padding: 7px 20px;
    border-radius: 30px;
    margin: 10px 10px 0px;
    opacity: 1;
    transition: .5s all ease-in-out;
    color: #fff;
}

.verify .btn:hover {
    opacity: 0.8;
    transition: .5s all ease-in-out;

}

.verify .btn a {
    color: #FFF;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px
}

.verify .save {
    background-color: #2cb742;
}

.verify .cancel {
    background-color: #ed2124
}


/** End Profile page **/
/* Smart Phones and Tablets */

@media (max-width: 529px)
{
    
    .navbar-brand img {
        width: 160px;
    }
    
    .header {
        background-image: url('../images/new-header-xs.jpg');
        margin-bottom: 0px;
        margin-top: -50px;
    }
    .header .heading {
        margin-top: 45%;
    }
    
    .header .heading h1 {
        font-size: 27px
    }
    
    .header .text p {
        font-size: 13px;
    }
    
    .header .text .btn {
        padding: 15px 30px;
        font-size: 16px
    }
    
    .header .text .btn:hover {
        padding: 14px 29px
    }
    
    .fa-long-arrow-alt-right {
        left: 89%;
    }
   
    
    .contact-us .contact .user {
        width: 80%;
        

    }
    
    /* .contact-us .contact-info {
        padding-bottom: 8rem
    } */
    
    .quote p {
        padding: 0 0 30px;
        font-size: 12px;
    }
    
    .how-work p {
        padding: 0;
  
    }
    .rules {
        padding: 0;
    }
    .rules .rule-text p {
        margin: -18% 0 50px 14%;
    }
    
    .games .game-image {
        width: 100%;
    }

    .how-claim {
        padding:30px 0 30px;
        background-color: #efefef;
    }
    .claim .claim-text p {
        margin-left: 35px;
        margin-top: -45px
    }

   
    /** Authenticated Pages **/
    
    .wallet {
        padding: 0
    }
    
    .wallet .profile-info img {
        margin-bottom: 20px
    }
    
    .wallet .profile-info .balance {
        text-align: center;
        padding-right: 0
    }
    
    .wallet .profile-info .balance p {
        font-size: 18px;
        margin-bottom: 20px
    }
    
    .wallet .profile-info .balance button {
        font-size: 18px
    }
    
    
    .wallet .wallet-info {
        margin-top: 19rem;
        padding: 0
    }
    
    .wallet-info .tabs {
        padding: 10px
    }
    
    .wallet-info .tabs h3 {
        font-size: 18px
    }
    
    
    .wallet-info .summary-info {
        font-size: 15px;
        padding: 0
    }
    
    
    .wallet-info .summary-info ul {
        padding-left: 20px
    }
    
    .wallet-info .summary-info .prizes {
        margin-right: 0
    }
    
    .wallet-info .summary-info .prizes h3 {
        font-size: 16px;
    }
    
    .wallet-info .summary-info .prizes h4 {
        font-size: 14px
    }
    
     .wallet-info .history-info p {
         margin-left: 10px;
         font-size: 14px
    }
    
    .withdraw .withdraw-q .panel-default > .panel-heading {
        margin: 50px 10px
    }
    
    .withdraw .withdraw-q {
        padding-bottom: 4.6rem;
    }
    
    
    .quiz .latest-winner {
        width: 100%;
        padding-bottom: 20px
        
    }
    
    
    .play p,
    .daily-results p {
        padding: 35px 20px;
    }
    
    .content .previous-winners , 
    .content .questions , 
    .content .ads {
        min-height: auto
    }
    
    .questions .answers input {
        width: 100%;
    }
    
    .content .questions {
        padding: 0 5px;
    }
    
    .content .questions h3 {
        padding: 0 15px 15px;
        margin: 0;
        margin-bottom: 25px;
    }
    
    .content .que-ans {
        margin-bottom: 3rem
    }
    
    .content .previous-winners,
    .content .questions,
    .content .ads {
        min-height: auto;
    }
    
    
    .content .previous-winners {
        min-height : auto;
        text-align: center;
        padding: 10px;
        width: 100%;
    }
    
    .content .previous-winners .winner {
        width: 50%;
        float: left;
    }

    .weekly-draw .claim {
        padding: 15px;
        width: 80%;
    }
    
    
    .weekly-draw .claim h3 {
        display: block;
        margin-bottom: 25px;
        margin-right: 0;
    }
    
    .next-weeklydraw h2 {
        font-size: 27px;
    }
    
    .weekly-draw h2 span {
        margin-left: 0;
        margin-top: 2rem

    }
    
    .winner-container {
        margin-bottom: 2rem
    }
    
    
    .top-winners .winner span {
        font-size: 22px;
    }
    
    .play {
        padding: 15px;
        
    }
    
    /* .play ,
    .daily-results {
       margin-bottom: 8rem; 
    } */
    
    
    .play p {
        padding: 25px;
        font-size: 28px;
        margin-bottom: 35px
    }
    
    
    .questions .answers .btn,
    .play .btn {
        font-size: 17px;
    }
    
    .ads-small .ad-here {
        min-height: 100px;
        background-size: cover;
        background-position: center;
        margin-right: 0;
    }
    
    
    .weekly-draw .map img {
        height: auto;
    }

    .games .game-tabs .tab {
        width: 90px;
        height: 60px;
        font-size: 10px;
    }

    .games .game-tabs .tab .status {
        height: 25px;
    }

    .games .game-tabs .tab .status i {
        font-size: 18px;
    }

    .games .game-tabs .tab .status .badge {
        font-size: 11px;
    }

    .brands img {
        width: 80%;
    }
    
    .summary-card-wrap {
        width: 100%;
        flex: none;
    }

    .accordion-content-wrap {
        padding: 0 10px;
    }

    .questions .answers .options-wrap .option {
        width: calc(100% - 20px);
    }
}

@media (min-width: 530px) and (max-width: 687px) {
    .header {
        margin-bottom: 0px;
        margin-top: -50px;
    }

    .header .heading {
        margin-top: 30%;
    }
    
     .header .heading h1 {
        font-size: 27px
    }
    
    .header .text p {
        font-size: 13px;
    }
    
   
    .contact-us .contact .user {
        width: 80%;
        

    }
    
/*      .contact-us .contact-info {
        padding-bottom: 8rem
    } */
    
    .quote p {
        padding: 0 30px 50px;
        font-size: 15px;
    }
    
    .how-claim {
        padding:0;
        background-color: #efefef;
    }
    
    .claim .claim-text p {
        margin-left: 35px;
        margin-top: -45px
    }
    
     /** Authenticated Pages **/
    
    .wallet {
        padding: 0
    }
    
    .wallet .profile-info img {
        margin-bottom: 20px
    }
    
    .wallet .profile-info .balance {
        text-align: center;
        padding-right: 2rem
    }
    
    .wallet .profile-info .balance p {
        font-size: 18px;
        margin-bottom: 20px
    }
    
    .wallet .profile-info .balance button {
        font-size: 16px
    }
    
    
    .wallet .wallet-info {
        margin-top: 15rem;
        padding: 0
    }
    
    .wallet-info .tabs {
        padding: 10px
    }
    
    .wallet-info .tabs h3 {
        font-size: 18px
    }
    
    
    .wallet-info .summary-info {
        font-size: 15px;
        padding: 0
    }
    
    
    .wallet-info .summary-info ul {
        padding-left: 20px
    }
    
    .wallet-info .summary-info .prizes {
        margin-right: 0
    }
    
    .wallet-info .summary-info .prizes h3 {
        font-size: 16px;
    }
    
    .wallet-info .summary-info .prizes h4 {
        font-size: 14px
    }
    
    .wallet-info .history-info p {
        margin-left: 0;
    }
    
    .edit-d .side-info {
        margin-top: -25px;
    }
    
    .edit-d .user-id {
        margin-top: 15%
    }
    
    .edit-d .side-info h3 {
        font-size: 15px;
    }
    
    .edit-d .side-info .btn {
        font-size: 15px;
    }
    
    .edit-d .details input:nth-of-type(3),
    .edit-d .details input:nth-of-type(4) {
        width: 22rem;
    }
    
    /* .verify .btn {
        margin: 40px 15px 40px;
    } */
    
    .content .previous-winners,
    .content .questions,
    .content .ads {
        min-height: auto;
    }
    
    
    
    .content .previous-winners {
        min-height : auto;
        text-align: center;
        padding: 10px;
        width: 100%;
    }
    
    .content .previous-winners .winner {
        width: 50%;
        float: left;
    }
    
    
    .top-winners .winner span {
        font-size: 24px;
    }
    
    
    .play {
        padding: 15px;        
    }
    
    .ads-small .ad-here {
        min-height: 100px;
        background-size: cover;
        background-position: center;
        margin-right: 0
    }
    
    
    .weekly-draw .map img {
        height: auto;
    }
    
    /* .play, 
    .daily-results {
        margin-bottom: 8rem;
    } */

    .games .game-tabs .tab {
        font-size: 13px;
    }
}


@media (min-width: 688px) and (max-width: 767px) {
    .header {
        margin-bottom: 0px;
        margin-top: -50px;
    }

    .header .heading {
        margin-top: 30%;
    }
    
     .header .heading h1 {
        font-size: 27px
    }
    
    .header .text p {
        font-size: 13px;
    }
    
   
    .contact-us .contact .user {
        width: 80%;
        

    }
    
     /* .contact-us .contact-info {
        padding-bottom: 8rem
    } */
    
    
    .rules .rule-text p {
        float: left;
        margin: -7% 0 50px 7%;
    }
    
    .how-claim {
        padding:0;
        background-color: #efefef;
    }
    
    .claim .claim-text p {
        margin-left: 16px;
        margin-top: 7px;

    }
    
    
    .wallet .wallet-info {
        margin-top: 15rem
    }
    
    .wallet .profile-info .balance {
        margin-top: 2rem
    }
    
    
    .wallet .wallet-info {
        padding: 20px
    }
    
    .wallet-info .summary-info {
        padding: 20px 10px
    }
    
    .edit-d .side-info {
        margin-top: -25px;
    }
    
    .edit-d .user-id {
        margin-top: 12%
    }
    
    /* .verify .btn {
        margin: 40px 20px 40px;
    } */
    
    .content .previous-winners,
    .content .questions,
    .content .ads {
        min-height: auto;
    }
    
    
    .content .previous-winners {
        text-align: center;
        padding: 10px;
        width: 100%;
    }
    
    .content .previous-winners .winner {
        width: 50%;
        float: left;
    }
    
    
    .play {
        padding: 15px;
        
    }    
    

    .ads-small .ad-here {
        min-height: 100px;
        background-size: cover;
        background-position: center;
        margin-right: 0;
    }
    
    
    .weekly-draw .map img {
        height: auto;
    }
    
    /* .play, 
    .daily-results {
        margin-bottom: 8rem;
    } */

    .games .game-tabs .tab {
        font-size: 13px;
    }

}

@media (max-width: 767px) {
    .games .game-tabs {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 9996;
        background: #fff;
        margin-bottom: 0;
        box-shadow: 0px 0 4px #222;
    }

    .content .questions h3 {
        margin: 0 0 25px;
    }
}

@media (min-width: 768px) {
    .navbar-right {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-nav .f-signin {
        /* margin: 0 0 0 20px; */
        margin: 10px auto;
    }

    .navbar-nav .dropdown-menu .f-signin {
        padding: 8px 0;
    }

    /* .navbar-header {
        float: none;
    } */

    .hamb-menu {
        padding-right: 0;
    }

    .questions .answers .btn, .play .btn {
        margin-left: auto;
        margin-right: 0;
    }

    .next-round {
        text-align: right;
        padding-right: 70px;
    }

}

@media (max-width: 991px) {
    .contact-us .contact {
        min-height: unset;
    }

    .contact-us .contact-info {
        min-height: unset;
    }
}

/* Small Screens */

@media (min-width: 768px) and (max-width: 991px)
{
    .header {
        margin-bottom: 0px;
        margin-top: -50px;
    }

    .navbar-default .navbar-nav > li > a {
        font-size: 15px;
    }   
    
     /* .contact-us .contact-info {
        padding-bottom: 8rem
    } */
    
    .rules .rule-text p {
        float: left;
        margin: -7% 0 50px 7%;
    }
    
    .wallet .wallet-info {
        padding: 20px 10px;
    }
    .wallet-info .summary-info {
        font-size: 18px;
        padding: 10px 40px;
    }
    
    .wallet-info .summary-info ul {
        padding-left: 0
    }
    
    .withdraw .withdraw-q {
        min-height: 430px;
        padding-bottom: 2rem
    }
    
     .edit-d .side-info {
        margin-top: -30px;
    }
    
    .edit-d .user-id {
        margin-top: 10%
    }
    
    .content .questions {
        padding: 0 30px;
    }

    /* .brands img {
        width: 40%;
    } */
    
    
    .ads-small .ad-here {
        min-height: 100px;
        background-size: cover;
        background-position: center;
        margin-right: 0;
        margin-bottom: 4rem
    }
    
    
    .weekly-draw .map img {
        height: auto;
    }
    
 
}


/* Medium Screens */

@media (min-width: 992px) and (max-width: 1199px)
{
    .header {
        margin-bottom: 0px;
        margin-top: -50px;
    }

    .faq .questions .panel-group .panel {
        width: 85%;
        margin-top: 15%;
        margin-left: 50px;
    }
    
    .withdraw .withdraw-q {
        padding-bottom: 16.6rem;
    }
    
    .brands img {
        width: 40%;
    }

    .u-footer .social-wrap {
        text-align: left;
    }  
    
    .games .game-image {
        width: 31%;
    }
}

/* Large Screens */

@media (min-width: 1200px)
{
    .header {
        margin-bottom: 60px;
        background: none;
        position: static
    }
    .header .text {
        /* padding-right: 0; */
        text-align: justify
    }

    /* .header .heading {
        position: absolute;
        top: 0;
        left: 74px;
        z-index: 1;
        margin-top: 0
    } */
    .header .heading h1 {
        font-size: 42px ;
        color: #212121;
        font-weight: 600;
        /* margin-top: 90px; */
        margin-top: 45px;

    }

    .header .text p {
        /* margin-top: 40%; */
        /* padding: 0 50px 40px; */
        line-height: 2.1;
        font-size: 17px;
        color: #6c6c6c;
    }

    .header .text .btn {
        /* margin-left: 20%; */
        color: #FFF;
        border-radius: 40px;
        background-color: #384078;
        padding: 15px 40px;
        font-size: 20px;
        transition: .5s background ease;

    }

    .header .text .btn:hover {
        border: 2px solid #384078;
        background: #FFF;
        transition: .5s background ease;
        padding: 14px 39px;
        color: #384078
    }

    .header .text .btn i {
        margin-right: 10px
    }

    .header .heading h1 span {
        color: #ed2124
    }

    .header .image {
        /* padding: 0;
        position: relative;
        top: 0;
        left: -24px; */
        /* overflow: hidden */
    }
    .header .h-image {
        height: 515px;
        background-image: url('../images/new-header.jpg');
        background-size: cover;
        background-position: center;
        box-shadow: 10px 10px 10px #DDD;        
        overflow: hidden
    }
    
    .faq .questions .panel-group .panel {
        width: 85%;
        margin-top: 9%;
        margin-left: 50px;
    }
    
    
    .withdraw .withdraw-info ul {
        margin-left: 0;
        padding-left: 40px
    }
    
    .play {
        padding: 0 15px
    }
    
    .u-footer .social-wrap {
        text-align: left;
    }  
    
    .games .game-image {
        width: 31%;
    }


/** End Header **/

}





* {
    
    list-style: none
}

body {
    
    font-family: 'Ubuntu', sans-serif;

}

.overlay, .overlay-dark  {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(40, 40, 40, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.overlay-dark {
    background: #000;
    position: fixed;
}

.flex-app {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
}

button[disabled] {
    opacity: 0.6 !important;
}

/** Start Navbar **/

.dropdown-menu {
    min-width: 180px;
}

.navbar-default {
    border: none ;
    background: #f2f2f2;
    margin-bottom: 0px;
    box-shadow: 0 2px 4px #ddd;
}

.navbar-brand {
    padding: 0;
    height: auto
}

.navbar-brand img {
    width: auto;
    height: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
}


.navbar-right {
    
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #ed2124;
    background: none;
}

.navbar-default .navbar-nav > li > a {
    color: #6c6c6c;
    font-size: 17px;
    font-weight: 600;
    padding: 15px 35px 15px;
    margin-bottom: 15px;
}

.navbar-default .dropdown-top-label {
    padding: 0 5px;
}

.navbar-nav .f-signin {
    background: #384078;
    border-radius: 50px;
    transition: 1s background ease;
    width: fit-content;
    margin: 30px auto 15px;
    border: 2px solid #384078;
}

.navbar-nav .f-signin:hover {
    background: #FFF;
    transition: .5s background ease;
}


.navbar-nav .f-signin a {
    color: #FFF !important;
    padding: 10px 30px !important;
    margin-bottom: 0px !important;
    transition: 1s color ease;
    text-decoration: none;
}

.navbar-nav .f-signin:hover a {
    color: #384078 !important;
    transition: .5s color ease;
}

.navbar-nav .f-signin i {
    margin-right: 10px
}


.hamb-menu-wrap .f-signin {
    background: #FFF;
    border-radius: 50px;
    transition: 1s background ease;
    width: fit-content;
    margin: 25px auto 15px;
    padding: 5px 0;
}

/* .hamb-menu-wrap .f-signin:hover {
    background: #eee;
    transition: .5s background ease;
} */

.hamb-menu-wrap .f-signin a {
    color: #384078 !important;
    padding: 10px 30px !important;
    margin-bottom: 0px !important;
    transition: 1s color ease;
    text-decoration: none;
}

.hamb-menu-wrap .f-signin:hover a {
    transition: .5s color ease;
}

.hamb-menu-wrap .f-signin i {
    margin-right: 10px
}


.navbar-toggle {
    margin-top: 45px;
    margin-right: 20px;
}

.navbar-default .navbar-toggle {
    border-color: #ed2124;
    transition: .5s all ease

}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    
    background: #ed2124;
    transition: .5s all ease

    
}


.navbar-default .navbar-toggle .icon-bar {
    background-color: #384078;
    transition: .5s all ease

}

.navbar-default .navbar-toggle:hover .icon-bar,
.navbar-default .navbar-toggle:focus .icon-bar {

    background-color: #FFF;
    transition: .5s all ease

}

.right-hamb-wrap {
    float: right;
    display: flex;
    position: relative;
    top: 30px; 
    align-items: center;
    justify-content: center; 
    transform: scale(1.1);
    left: -5px;  
}

.hamb-menu {
    display: block;
    border-radius: 4px;
    padding-right: 15px;
    padding-left: 15px;
}

.hamb-menu i {
    font-size: 24px;
    color: #233b71;
    transition: all 0.5s ease;
    cursor: pointer;
}

.hamb-menu i.fa-times {
    color: #fff;
}

.hamb-menu-wrap {
    position: fixed;
    background: rgba(35, 59, 113, 0.95);
    color: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    padding-top: 15px;
    padding-left: 0;
    width: 225px;
    z-index: 9999;
    transform: translateX(225px);
    will-change: transform, opacity;
    transition: transform 0.5s ease;
}

.hamb-menu-wrap ul {
    padding: 0;
}

.hamb-menu-wrap li {
    padding: 15px 25px;
    border-bottom: 1px solid #444e94;
    font-size: 16px;
}

.hamb-menu-wrap .split-col,
.dropdown-menu .split-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-menu .split-col .badge {
    background: #233b71;
    color: #fff;
    margin-left: 20px;
}

.hamb-menu-wrap .split-col .badge {
    background: #fff;
    color: #233b71;
}

.hamb-menu-wrap li i {
    cursor: pointer;
}

.hamb-menu-wrap a {
    color: #fff;
    text-decoration: none;
}

.hamb-menu-wrap .with-submenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hamb-menu-wrap .sub-menu-wrap {
    display: none;  
    background: rgba(28, 48, 90, 0.5);
}

.hamb-menu-wrap .sub-menu-wrap li {
    border: 0;
}

.hamb-menu-wrap .sub-menu-wrap.visible {
    display: block;
    margin: 15px -25px -15px;      
}


.hamb-menu-wrap a .avatar {
    margin-left: 15px;
}

.hamb-menu-wrap.visible {
    transform: translateX(0);
    box-shadow: -2px 0 4px #333;
}

.hamb-menu-wrap.visible .menu-overlay {
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0);
    width: calc(100vw - 225px);
    left: calc(225px - 100vw);
    opacity: 0;
    animation: overlay 0.25s ease 0.5s;
    animation-fill-mode: forwards;
}

@keyframes overlay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

/** End Navbar **/

/** Start Header **/
.header {
    margin-bottom: 40px;
    background-image: url('../images/new-header-responsive.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    top: 0;
    left: 0;
}
.header .text {
    /* padding-right: 0; */
    text-align: center
    
}

.header .heading {
    margin-top: 23%
}
.header .heading h1 {
    font-size: 42px ;
    color: #FFF;
    font-weight: 600;
   
}

.header .text p {
    margin-top: 30px;
    line-height: 2.1;
    font-size: 17px;
    color: #DDD;
}

.header .text .btn {
    color: #FFF;
    border-radius: 40px;
    background-color: #384078;
    padding: 15px 40px;
    font-size: 20px;
    transition: .5s background ease;
    margin-bottom: 40px

}

.header .text .btn a {
    text-decoration: none;
    color: #FFF;
}

.header .text .btn:hover a {
    color: #384078
}

.header .text .btn:hover {
    border: 2px solid #384078;
    background: #FFF;
    transition: .5s background ease;
    padding: 14px 39px;
    color: #384078
}

.header .text .btn i {
    margin-right: 10px
}

.header .heading h1 span {
    color: #FFF
}



/** End Header **/

/** Start FAQ **/
/* .faq {
    margin-bottom: 70px
} */
.faq h2 {
    text-transform: uppercase;
    color: #ed2124;
    margin-top: 60px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 28px
    
}

.faq h2::after {
    content: "";
    position: relative;
    top: 0;
    left: -4px;
    width: 48px;
    margin: auto;
    display: block;
    border: 1.5px solid #384078
}





.faq .f-image img {
    width: 100%
}

.faq .questions .panel-group .panel + .panel {
    margin-top: 0;
}

.faq .questions .panel-group .panel-default {
    border: none
}
.faq .questions .panel-group .panel {
    width: 100%;
    margin-top: 9%;
    margin-left: 0;
}

.faq .questions .panel-default > .panel-heading {
    padding: 23px;
    border-radius: 50px;
    text-align: center
}

.faq .questions .panel-default .two,
.faq .questions .panel-default .four {
    background-color: #9a9a9a
}

.faq .questions .panel-default .one,
.faq .questions .panel-default .three,
.faq .questions .panel-default .five {
    background-color: #DDD
}

.faq .questions .panel-default a {
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
    color: #111;
    text-decoration: none;
}
.faq .questions .panel-default .one a,
.faq .questions .panel-default .three a,
.faq .questions .panel-default .five a {
    color: #384078;
}

.faq .questions .panel-default a i {
    margin-left: 10px
}

.faq .questions .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border: 1px solid #CCC;
    border-radius: 25px;
    line-height: 1.9;
    color: #212121;
    font-size: 15px;
}
/** End FAQ **/

/** Start Winners **/
.winners {
    background-color: #efefef;
    padding-bottom: 30px
}

.winners h2 {
    color: #ed2124;
    margin: 60px 0 40px;
    font-weight: bold;
    font-size: 25px
    
}

.winners h2::after {
    content: "";
    position: relative;
    top: 0;
    left: -35px;
    width: 70px;
    margin: auto;
    display: block;
    border: 1.5px solid #384078
}
.winners .owl-carousel .item {
    /*width: 315px;*/
    padding: 10px 20px 20px;
    background-color: #FFF;
    box-shadow: 10px 10px 10px #CCC;
    margin-bottom: 20px;
    margin: 20px
}

.winners .owl-carousel .item img {
    width: 50%;
    margin: 40px auto 20px;
    border-radius: 50%
}

.winners .owl-carousel .item span {
    color: #ed2124;
    font-size: 30px;
    font-weight: bold;
}

.winners .owl-carousel .item p {
    margin: 20px 0 50px;
    color: #797979;
    font-weight: bolder
}

.winners .owl-carousel .item h4 {
    color: #384078;
    font-size: 25px;
    margin-bottom: 30px
}



.fa-long-arrow-alt-right {
    position: absolute;
    top: 45%;
    left: 97%;
    font-size: 30px;
}

.fa-long-arrow-alt-left {
    position: absolute;
    top: 45%;
    left: 9px;
    font-size: 30px;
}

.fa-long-arrow-alt-right:hover,
.fa-long-arrow-alt-left:hover {
    color: #ed2124
}

/** End Winner **/

/** Start Ultimate footer **/
.u-footer {
    background-color: #222;
    padding: 30px 0 10px
}

.u-footer .social-wrap {
    text-align: center;
}

.u-footer .logo {
    padding: 0 10px;
}

.u-footer .logo img {
    width: 70px;
    height: 70px;
    margin-bottom: 0px;
}


.u-footer p {
    color:#bfbfbf;
    padding: 0 10px;
}

.u-footer .social {
    padding: 0 5px;
    margin-top: 20px;
    margin-bottom: 40px
}
.u-footer .social i {
    color: #222;
    background: #bfbfbf;
    width: 30px;
    height: 30px;
    font-weight: bolder;
    text-align: center;
    margin: 5px;
    padding-top: 9px;
    transition: .5s all ease

}

.u-footer .social i:hover {
    background: #FFF;
    cursor: pointer;
    transition: .5s all ease
}



.u-footer h3 {
    font-size: 26px;
    margin-bottom: 30px;
    color: #bfbfbf;
    font-weight: bold;
    text-align: center;
}

.u-footer .quicklink {
    width: fit-content;
    margin: auto;
}

.u-footer ul {
    padding-left: 0;
    margin-bottom: 40px;
}

.u-footer .quicklink li {
    margin-bottom: 10px
}
.u-footer .quicklink li i {
    transition: 0.5s all ease;
    padding-right: 15px;
}

.u-footer .quicklink li a {
    color: #EEE;
    text-decoration: none;
    font-size: 18px;
    transition: 0.5s all ease

}

.u-footer .quicklink li a:hover {
    color: #ed2124;
    transition: 0.5s all ease

}

.u-footer .quicklink li a:hover i,
.u-footer .quicklink li a:focus i {
    opacity: 1;
    transition: 0.5s opacity ease;


}

.u-footer .quicklink .active a {
    color: #ed2124
}

.u-footer .f-contact {
    width: fit-content;
    margin: auto;
}

.u-footer .f-contact h3 {
    text-align: center;
}

.u-footer .f-contact li {
    color: #EEE;
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 1.8;
}

.u-footer .f-contact li a {
    color: #EEE;
    text-decoration: none;
    transition: 0.5s all ease
}

.u-footer .f-contact li:hover a {
    color: #ed2124;    
}

.u-footer .f-contact i {
    font-size: 25px;
}
/** End Ultimate footer **/

/** Start Footer **/
footer {
    background: #222;
    border-top: 1px solid #bfbfbf;
    padding: 20px 0;
}

footer h3 {
    font-size: 14px;
    margin: 0;
    color: #bfbfbf
}
/** End Footer **/

/** Content pages **/
.rules-page .accordion .content {
    min-height: unset;
}

.rules-page,
.how-to-claim,
.privacy-policy {
    padding: 0 15px;
}

h2 {
    margin-bottom: 30px;
    color: #ed2124;
    font-weight: bold
}

h2:after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 60px;
    display: block;
    border: 1.5px solid #384078
}

.rules-page ol, .rules-page ol li {
    list-style-type: decimal;
}
/** End Rules page **/

/** Accordion **/
.ui.styled.accordion, .ui.styled.accordion .accordion {
    box-shadow: none;
    font-size: 16px;
    margin: 0 -15px;
}

.ui.styled.accordion .accordion .title, .ui.styled.accordion .title {
    border: 0;
}

.ui.styled.accordion .active.title {
    color: #384078;
}

.ui.accordion .title:not(.ui) {
    font-size: 1.2em;    
}

.abc .ui.accordion:not(.styled) .title~.content:not(.ui) {
    /* padding: .5em 0 1em; */
    padding: 0;
}
.abc .ui.accordion .title~.content {
    /* display: none; */
    display: block;
}

.abc .expand {
    transition: .4s max-height ease-out, .4s opacity ease-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden; 
}

.abc .active.expand {
    max-height: 1000px;
    opacity: 1;
}

/** Form Styles **/
form .input-row {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

form .input-row label {
    width: 160px;
    margin: 10px 0;
    font-size: 14px;
    color: #727272;
}

form .input-row input:not([type=radio]) {
    flex: 1;
    max-width: 350px;
    box-shadow: 0 0 3px #bbb;
    border-radius: 2px;
}

form .input-row input:focus {
    box-shadow: 0 0 3px #384078;    
}


form input[type="date"] {
    line-height: inherit;
}

form .input-row input[type=radio] {
    box-shadow: none;
    margin: 0;
}

form .input-row input[type=radio] + span {
    padding-right: 25px;
    padding-left: 5px;
}

form input[readonly], form input[readonly]:hover, form input[readonly]:focus {
    box-shadow: none !important;
    background-color: #f8f8f8;
}

/* Modal */
.modal-container {
    padding: 15px 25px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 8px #666;
    max-height: 100vh;
    overflow: auto;
    max-width: 560px;
    width: 100%;
    position: relative;
}

.modal-container .close-icon {
    position: absolute;
    top: 10px;
    font-size: 32px;
    right: 25px;
    cursor: pointer;
}
